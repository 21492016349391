<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <!-- v-model:modelPolicyStatus="filter.policy_status" -->
      <vc-report-filter
        v-model:modelStartDate="filter.start_date"
        v-model:modelEndDate="filter.end_date"
        v-model:modelSearch="filter.search"
        v-model:modelInvoiceType="filter.invoice_type"
        v-model:modelPaymentType="filter.payment_type"
        showPaymentType
        show-invoice-type
        show-export-reset-first-row
        show-export-reset-second-row
        :show-export-first="false"
        :show-reset-first="false"
        @reset="reset"
        @export="exportData"
        is-agent-multiple
        is-policy-status-multiple
        showTextFilterFirst
        :showExportResetFirstRow="true"
        :showExportResetSecondRow="true"
        :export-url="exportUrl"
        :textFilterPlaceHolder="'Quote Reference No'"
        :autoSuggest="true"
      />

      <vc-approved-invoices-table />

    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Report from "@/core/services/Callable/Report";
import { store } from "@/store";
import moment from "moment";

const filter = computed(() => {
  return store.state.report.approved_invoices_filter
})

const loading = ref(false);
const exportUrl = ref('')

onMounted(async () => {
  setCurrentPageBreadcrumbs("Approved Invoices", [{ label: "Reports", value: "" }]);
  filter.value.start_date = moment().startOf("month").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("month").format("YYYY-MM-DD");
  await searchData(filter.value);
});

watch(
  [() => filter.value],
  async ([newValue], [oldValue]) => {
    exportUrl.value = '';
    searchData(newValue);
  },
  {
    deep: true,
  }
);

const searchData = async (value) => {
  loading.value = true
  const data = await Report.getApprovedInvoicesReport(value);
  if(value.export){
    exportUrl.value = data
      // window.open(data)
  }
  loading.value = false

};

const reset = async () => {
  console.log("reset");
  filter.value.page = 1;
  filter.value.search = "";
  filter.value.payment_type = "";
  filter.value.invoice_type = "";
  filter.value.start_date = moment().startOf("month").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("month").format("YYYY-MM-DD");

  await searchData(filter.value);
};

const exportData = () => {
  const payload = {
    ...filter.value,
    export:true
  }
  searchData(payload)
  console.log("export");
};
</script>
